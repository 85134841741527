
h1 {
  color: rgb(187, 101, 3);
  text-align: center;
  font-family:'Droid Arabic Naskh'
}
.unwan1 {
  color: rgb(9, 20, 65);
  text-align: center;
  font-family:'Droid Arabic Naskh'
}

.unwan {
  color:  rgb(98, 2, 2);
  text-align: center;
  font-family:'Droid Arabic Naskh'
}
h3 {
  color: rgb(98, 2, 2);
  text-align: center;
  font-family:'Droid Arabic Naskh'
}
label {
  color:rgb(9, 20, 65);
  font-family:'Droid Arabic Naskh'
     
}

p{
  font-family:'Droid Arabic Naskh'
}
