
.container{
    background-color: rgb(152, 164, 137);
}

h1 {
    color: rgb(16, 4, 96);
    text-align: center;
    font-family:'Droid Arabic Naskh';
    margin-top: 20px;
  }

  .unwan1 {
    color: rgb(18, 3, 103);
    text-align: center;
    font-family:'Droid Arabic Naskh';
    
  }
  
  .unwan {
    color:  rgb(98, 2, 2);
    text-align: center;
    font-family:'Droid Arabic Naskh'
  }
  h3 {
    color: rgb(98, 2, 2);
    text-align: center;
    font-family:'Droid Arabic Naskh'
  }
  label {
    color:rgb(18, 3, 103);   
    font-family:'Droid Arabic Naskh'
       
  }
  
  p{
    font-family:'Droid Arabic Naskh'
  }
  